import { createContext, useCallback, useRef, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { getApiDomain } from 'src/get_hostname';
import { gql, useQuery } from '@apollo/client';
import { makeUseAxios } from 'axios-hooks';
import Cookies from 'js-cookie';
import { FETCH_RECEIVER } from './views/Shop/queries.gql';
import jwtDecode from 'jwt-decode';
import { useTranslation } from 'react-i18next';

const currencies = gql`
  query currency {
    currencies: core_currency {
      id
      value
      label: currency
    }
  }
`;

const fetchStores = gql`
  query stores($stores: [Int!]) {
    stores: store_store(where: { id: { _in: $stores } }) {
      value: id
      label: name
      senders: store_store_senders {
        id
        customers_sender {
          value: id
          label: name
        }
      }
      warehouselog_expense_type: expenses_expensetype {
        value: id
        label: name
      }
    }
  }
`;
const USER = gql`
  query userContex($id: Int!) {
    liqpayConfig: liqpay_liqpayconfig_by_pk(id: 1) {
      id
      currency
    }
    accounts_user_by_pk(id: $id) {
      id
      first_name
      last_name
      middle_name
      role
      username
      is_staff
      is_superuser
      companion
      temporary_blocked
      blocked_href
      last_login
      date_joined
      multiple
      email
      email_verified
      bank_requisites
      communal_filled
      looked_audit_course
      staffStructures: accounts_staffstructures {
        category
        subordinates: accounts_staffstructure_subordinates {
          user: accounts_user {
            id
            first_name
            last_name
          }
        }
      }
      motivation: motivation_motivation {
        id
        salary_per_hour
        vat_percent
        mentor_percent
        salary
        salary_per_hour
        collection_percent
        invite_percent
        student_percent
      }
      studentProfile: accounts_studentprofile {
        who_studing
      }
      profile: accounts_profile {
        id
        birth_date
        sighted
        is_user_agreement
        user_agreement_comment
        new_employee_approved_hr
        avatar
        parlor_id
        phone_number
        gift_newsletter
        main_parlor: core_parlor {
          id
          name
          birthday
        }
        responsible_salons: accounts_profile_responsible_salons {
          id
          parlor_id
          parlor: core_parlor {
            id
            name
          }
        }
        parlors: accounts_profile_parlors(where: { core_parlor: { is_active: { _eq: true } } }) {
          core_parlors: core_parlor {
            label: name
            value: id
            city_code: city_id
            point: category
            point_count: category_count
            type: status
            status: status
            start_time: start_time
            phone_number: phone_number
            wifi_name: wifi_name
            wifi_password: wifi_password
            end_time: end_time
            payment_methods: payment_methods
            is_laser: is_laser
            full_range_of_services: full_range_of_services
            native_speaker: native_speaker
            junior_master
            place_of_study
            need_duty_master
            number_of_jobs
            number_maximum_workplaces
            is_rent
            is_always_laser: is_always_laser
            temporary_inactive: temporary_inactive_lounge
            economists_recommendations: economists_recommendations
            director_id
            isFlagman: is_flagman
            birthday
            pause_office
            pause_studio
            director: accounts_user {
              id
              last_name
              middle_name
              first_name
              profile: accounts_profile {
                phone_number
              }
            }
          }
        }
      }
      core_marketing {
        id
        name
      }
      depart: core_department {
        id
        name
      }
      store_owners: store_store_owners {
        id
        store_id
      }
      store_moderators: store_store_moderators {
        id
        store_id
      }
      store_storekeepers: store_store_storekeepers {
        id
        store_id
      }
      store_storekeepers: store_store_storekeepers {
        id
        store_id
      }
      client: customers_receiver {
        id
        client_stores: store_store_receivers {
          store_id
        }
      }
      activity: activity_activities_aggregate(
        where: { end_at: { _is_null: false }, date: { _lt: "now" } }
      ) {
        aggregate {
          count
        }
      }
    }
  }
`;

export const CurrencyContext = createContext();

export const UserContext = createContext({
  token: '',
  user: {
    id: null,
    parlors: [],
    profile: { parlors: [] },
    role: 'guest',
  },
  api: axios,
  api_v2: axios,
  userPrice: '',
});

export const ParlorContext = createContext({
  parlorData: null,
  parlor: null,
  setParlor: console.log,
});
export const ThemeContext = createContext('');
export const NotificationContext = createContext(null);

const useTokens = (broadcastChannel) => {
  const [tokens, setTokensState] = useState(() => {
    const savedTokens = Cookies.get('tokens');
    return savedTokens ? JSON.parse(savedTokens) : null;
  });

  const setTokens = useCallback(
    (newTokens) => {
      if (newTokens) {
        Cookies.set('tokens', JSON.stringify(newTokens), { expires: 1 });
        setTokensState(newTokens);
        broadcastChannel.postMessage('tokensUpdated');
      }
    },
    [broadcastChannel],
  );

  const clearTokens = useCallback(() => {
    if (tokens) {
      Cookies.remove('tokens');
      setTokensState(null);
      broadcastChannel.postMessage('clearAll');
    }
  }, [broadcastChannel, tokens]);

  useEffect(() => {
    const handleBroadcast = (event) => {
      if (event.data === 'tokensUpdated') {
        const tokensFromCookies = Cookies.get('tokens');
        if (tokensFromCookies) {
          setTokensState(JSON.parse(tokensFromCookies));
        }
      } else if (event.data === 'clearAll') {
        window.location.reload();
      }
    };

    broadcastChannel.addEventListener('message', handleBroadcast);
    return () => {
      broadcastChannel.removeEventListener('message', handleBroadcast);
    };
  }, [broadcastChannel, clearTokens]);

  return { tokens, setTokens, clearTokens };
};

const useTokenRefresh = (tokens, setTokens, clearTokens, refetchUserContext) => {
  const refreshTimerRef = useRef(null);
  const refreshingPromiseRef = useRef(null);

  const clearRefreshTimer = useCallback(() => {
    if (refreshTimerRef.current) {
      clearTimeout(refreshTimerRef.current);
      refreshTimerRef.current = null;
    }
  }, []);

  const refreshTokens = useCallback(async () => {
    const savedTokens = Cookies.get('tokens');
    const refreshToken = savedTokens ? JSON.parse(savedTokens)?.refresh : null;
    if (!refreshToken) {
      clearTokens();
      return;
    }

    if (refreshingPromiseRef.current) {
      return refreshingPromiseRef.current;
    }

    clearRefreshTimer();

    refreshingPromiseRef.current = axios
      .post(`${getApiDomain()}/auth/jwt/refresh/`, {
        refresh: refreshToken,
      })
      .then(({ data }) => {
        setTokens({ refresh: refreshToken, access: data.access });
        refreshingPromiseRef.current = null;
        // refetchUserContext();
        return data.access;
      })
      .catch((err) => {
        refreshingPromiseRef.current = null;
        if (err.response?.status === 401) {
          clearTokens();
        }
        throw err;
      });

    return refreshingPromiseRef.current;
  }, [tokens, setTokens, clearTokens, clearRefreshTimer]);

  const setRefreshTimer = useCallback(() => {
    const savedTokens = Cookies.get('tokens');
    const accessToken = savedTokens ? JSON.parse(savedTokens)?.access : null;
    const refreshToken = savedTokens ? JSON.parse(savedTokens)?.refresh : null;
    if (!accessToken && refreshToken) {
      refreshTokens();
    } else if (!accessToken) {
      return;
    }
    const decoded = jwtDecode(accessToken);
    const expirationTime = decoded.exp * 1000 - Date.now();
    const refreshTime = expirationTime - 60 * 1000;

    clearRefreshTimer();

    if (refreshTime > 0) {
      refreshTimerRef.current = setTimeout(() => {
        refreshTokens();
      }, refreshTime);
    }
  }, [clearRefreshTimer, refreshTokens]);

  useEffect(() => {
    setRefreshTimer();
    return clearRefreshTimer;
  }, [tokens, setRefreshTimer, clearRefreshTimer]);

  return { refreshTokens };
};

const useAxiosInstance = (tokens, refreshTokens, language) => {
  const createInstance = useCallback(
    (version) => {
      const cleanedLang = language.endsWith('G') ? language.slice(0, -1) : language;

      const processedLocale = cleanedLang === 'ukr' ? 'uk' : cleanedLang || 'en';

      const instance = axios.create({
        baseURL: getApiDomain(version),
        headers: {
          Authorization: tokens?.access ? `Bearer ${tokens.access}` : '',
          'Accept-Language': processedLocale,
          'Content-Language': processedLocale,
        },
      });

      instance.interceptors.response.use(
        (response) => response,
        async (error) => {
          const originalRequest = error.config;
          if (tokens?.access && error.response?.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            try {
              const newAccessToken = await refreshTokens();
              originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
              return axios(originalRequest);
            } catch (err) {
              return Promise.reject(err);
            }
          }
          return Promise.reject(error);
        },
      );

      return instance;
    },
    [tokens?.access, refreshTokens, language],
  );

  const api = useMemo(() => createInstance(), [createInstance]);
  const api_v2 = useMemo(() => createInstance(2), [createInstance]);
  const api_v3 = useMemo(() => createInstance(3), [createInstance]);
  const api_v4 = useMemo(() => createInstance(4), [createInstance]);

  const useApiV1 = useMemo(() => makeUseAxios({ axios: createInstance() }), [createInstance]);
  const useApiV2 = useMemo(() => makeUseAxios({ axios: createInstance(2) }), [createInstance]);

  return { api, api_v2, api_v3, api_v4, useApiV1, useApiV2 };
};

const useAuthentication = () => {
  const broadcastChannel = useMemo(() => new BroadcastChannel('auth'), []);

  const { tokens, setTokens, clearTokens } = useTokens(broadcastChannel);
  const decodedToken = tokens?.access ? jwtDecode(tokens.access) : null;

  const {
    data,
    loading: loadingUser,
    refetch,
  } = useQuery(USER, {
    variables: { id: decodedToken?.user_id },
    fetchPolicy: 'cache-and-network',
    skip: !decodedToken?.user_id,
  });
  if (!loadingUser && !data) {
    refetch();
  }
  const { refreshTokens } = useTokenRefresh(tokens, setTokens, clearTokens, refetch);
  const [requireOtp, setRequireOtp] = useState(false);
  const { i18n } = useTranslation();
  const language = i18n.language === 'ukr' ? 'ua' : i18n.language;

  const { api, api_v2, api_v3, api_v4, useApiV1, useApiV2 } = useAxiosInstance(
    tokens,
    refreshTokens,
    language,
  );

  const fetchTotpAndDeviceStatus = () => {
    api
      .get('check-device-status/', {
        params: { username: decodedToken?.username },
      })
      .then((response) => {
        setRequireOtp(response.data.hasConfirmedTotp);
      })
      .catch((error) => {
        setRequireOtp(false);
      });
  };

  useEffect(() => {
    fetchTotpAndDeviceStatus();
  }, [decodedToken?.username]);

  const user = useMemo(() => {
    if (!data) return null;

    const userData = data.accounts_user_by_pk;

    const getStores = () => {
      const storekeeperIds = userData.store_storekeepers.map((item) => item.store_id);
      const moderatorIds = userData.store_moderators.map((item) => item.store_id);
      const ownerIds = userData.store_owners.map((item) => item.store_id);

      const allStoreIds = [...storekeeperIds, ...moderatorIds, ...ownerIds];
      return [...new Set(allStoreIds)];
    };

    return {
      requireOtp: requireOtp,
      gift_newsletter: userData.profile.gift_newsletter,
      motivation: userData.motivation,
      currencies: data?.liqpayConfig?.currency,
      bank_requisites: userData.bank_requisites,
      email: userData.email,
      email_verified: userData.email_verified,
      id: userData.id,
      isRegionalManager: userData.staffStructures?.some((f) => f.category === 'regional_manager'),
      communal_filled: userData.communal_filled,
      phone_number: userData.profile?.phone_number,
      profileId: userData.profile?.id,
      activityCount: userData.activity?.aggregate?.count,
      sighted: userData.profile?.sighted,
      is_user_agreement: userData.profile?.is_user_agreement,
      user_agreement_comment: userData.profile?.user_agreement_comment,
      new_employee_approved_hr: userData.profile?.new_employee_approved_hr,
      first_name: userData.first_name || '',
      last_name: userData.last_name || '',
      middle_name: userData.middle_name || '',
      role: userData.role,
      looked_audit_course: userData.looked_audit_course,
      username: userData.username,
      is_staff: userData.is_staff,
      is_superuser: userData.is_superuser,
      companion: userData.companion,
      temporary_blocked: userData.temporary_blocked,
      blocked_href: userData.blocked_href,
      last_login: userData.last_login,
      date_joined: userData.date_joined,
      who_studing: userData.studentProfile?.who_studing || null,
      birth_date: userData.profile?.birth_date,
      avatar: userData.profile?.avatar || null,
      multiple: userData.multiple,
      redirect_to_candidate_questionnaire: false,
      marketing_tag: {
        id: userData.core_marketing?.id,
        name: String(userData.core_marketing?.name),
      },
      department: {
        id: userData.depart?.id,
        name: String(userData.depart?.name),
      },
      main_parlor_info: {
        id: userData.profile?.main_parlor?.id,
        name: userData.profile?.main_parlor?.name,
        birthday: userData.profile?.main_parlor?.birthday,
      },
      main_parlor: userData.profile?.parlor_id,
      responsible_salons: userData.profile?.responsible_salons,
      parlors: userData.profile?.parlors.map((parlor) => ({
        label: parlor?.core_parlors?.label,
        birthday: parlor?.core_parlors?.birthday,
        pause_office: parlor?.core_parlors?.pause_office,
        pause_studio: parlor?.core_parlors?.pause_studio,
        value: parlor?.core_parlors?.value,
        city_code: parlor?.core_parlors?.city_code,
        point: parlor?.core_parlors?.point,
        point_count: parlor?.core_parlors?.point_count,
        type: parlor?.core_parlors?.type,
        status: parlor?.core_parlors?.status,
        start_time: parlor?.core_parlors?.start_time,
        phone_number: parlor?.core_parlors?.phone_number,
        wifi_name: parlor?.core_parlors?.wifi_name,
        wifi_password: parlor?.core_parlors?.wifi_password,
        end_time: parlor?.core_parlors?.end_time,
        payment_methods: parlor?.core_parlors?.payment_methods,
        is_laser: parlor?.core_parlors?.is_laser,
        full_range_of_services: parlor?.core_parlors?.full_range_of_services,
        native_speaker: parlor?.core_parlors?.native_speaker,
        junior_master: parlor?.core_parlors?.junior_master,
        place_of_study: parlor?.core_parlors?.place_of_study,
        need_duty_master: parlor?.core_parlors?.need_duty_master,
        number_of_jobs: parlor?.core_parlors?.number_of_jobs,
        number_maximum_workplaces: parlor?.core_parlors?.number_maximum_workplaces,
        is_rent: parlor?.core_parlors?.is_rent,
        is_always_laser: parlor?.core_parlors?.is_always_laser,
        isFlagman: parlor?.core_parlors?.isFlagman,
        temporary_inactive: parlor?.core_parlors?.temporary_inactive,
        economists_recommendations: parlor?.core_parlors?.economists_recommendations,
        director: {
          label: [
            parlor?.core_parlors?.director?.first_name,
            parlor?.core_parlors?.director?.middle_name,
            parlor?.core_parlors?.director?.last_name,
          ]
            .filter(Boolean)
            .join(' ')
            .trim(),
          value: parlor?.core_parlors?.director?.id,
          phone_number: parlor?.core_parlors?.director?.profile?.phone_number,
        },
      })),
      stores: {
        storekeepers: !!userData.store_storekeepers.length,
        moderators: !!userData.store_moderators.length,
        owners: !!userData.store_owners.length,
        store: getStores(),
      },
      client_stores: userData.client?.client_stores?.map((el) => el.store_id) || [],
      receiver: userData.client?.id,
    };
  }, [data, requireOtp]);

  return [
    api,
    api_v2,
    api_v3,
    api_v4,
    useApiV1,
    useApiV2,
    setTokens,
    tokens?.access,
    clearTokens,
    user,
    refetch,
    loadingUser,
  ];
};

const useParlor = (user) => {
  const [parlor, setParlorState] = useState(() => {
    const parlorCookie = Cookies.get('parlor');
    return parlorCookie ? JSON.parse(parlorCookie) : null;
  });

  const setParlor = useCallback((newParlor) => {
    setParlorState(newParlor);
    if (newParlor) {
      Cookies.set('parlor', JSON.stringify(newParlor), { expires: 1 });
    } else {
      Cookies.remove('parlor');
    }
  }, []);

  const parlorData = useMemo(() => {
    if (!user || !user.parlors?.length) return null;

    const selectedParlor = user.parlors.find((el) => el.value === parlor);
    return selectedParlor || user.parlors[0];
  }, [parlor, user]);

  useEffect(() => {
    if (!user || !user.parlors?.length) return;

    if (!parlor) {
      const activeParlor = user.parlors.find((el) => !el.temporary_inactive);
      if (activeParlor) {
        setParlor(activeParlor.value);
      }
    }

    const selected = user.parlors.find((el) => el.value === parlor);
    if (!selected) {
      setParlor(user.parlors[0].value);
    }
  }, [parlor, user, setParlor]);

  useEffect(() => {
    if (parlorData) {
      localStorage.setItem('parlorData', JSON.stringify(parlorData));
    } else {
      localStorage.removeItem('parlorData');
    }
  }, [parlorData]);

  return [parlor, setParlor, parlorData];
};

const useCurrency = () => {
  const initial = localStorage.getItem('currency');
  const [currency, setCurrency] = useState(initial);
  const { data } = useQuery(currencies);

  const findCurrency = useCallback(
    (currency_id) => data?.currencies?.find((el) => el.id === (currency_id ?? currency)),
    [data, currency],
  );

  useEffect(() => {
    if (currency !== initial) localStorage.setItem('currency', currency);
  }, [currency, initial]);

  const convert = useCallback(
    (value, currency_id) => {
      const currency = findCurrency(currency_id);
      if (currency) return currency.value * value;
      return value;
    },
    [findCurrency],
  );

  const convertToCRM = useCallback(
    (value, currency_id) => {
      const currency = findCurrency(currency_id);

      if (currency) return value / currency.value;
      return value;
    },
    [findCurrency],
  );

  return [convert, setCurrency, data?.currencies, findCurrency(), convertToCRM];
};

const useTheme = () => {
  const preferredTheme = 'black-content';
  const initial = localStorage.getItem('newTheme') || preferredTheme;
  const [theme, setTheme] = useState(initial);

  const toggleTheme = useCallback(() => {
    setTheme((prev) => (prev === 'white-content' ? 'black-content' : 'white-content'));
  }, []);

  useEffect(() => {
    localStorage.setItem('newTheme', theme);

    if (theme === 'white-content') {
      document.body.classList.add('white-content');
      document.body.classList.remove('black-content');
    } else if (theme === 'black-content') {
      document.body.classList.remove('white-content');
      document.body.classList.add('black-content');
    }
  }, [theme]);

  return [theme, setTheme, toggleTheme];
};

const useUserGetPrice = (user) => {
  const { department, role, id } = user ?? {};

  const { data } = useQuery(FETCH_RECEIVER('query', department), {
    variables: {
      role: role,
      ...(department?.name ? { department: department?.name } : {}),
      account: id,
    },
    skip: !id,
  });

  const getPrice = useCallback(
    (receivers) => {
      if (receivers?.find((receiver) => receiver?.account_id === id)) {
        return receivers.find((receiver) => receiver?.account_id === id)?.price;
      }
      if (department && receivers?.find((receiver) => receiver?.department === department?.name)) {
        return receivers.find((receiver) => receiver?.department === department?.name)?.price;
      }
      if (receivers?.find((receiver) => receiver?.role === role)) {
        return receivers.find((receiver) => receiver?.role === role && receiver?.price)?.price;
      }
      return '';
    },
    [department, role, id],
  );
  const userPrice = getPrice(data?.customers_receiver);
  return [userPrice];
};

const useStores = (user) => {
  const { data } = useQuery(fetchStores, {
    variables: { stores: user?.stores?.store },
    skip: !user?.stores?.store.length,
    fetchPolicy: 'cache-and-network',
  });
  return useMemo(() => {
    if (!data) return [[]];
    const newData = Array.from(data?.stores);
    function customSort(item1, item2) {
      const index1 = user?.stores?.store.indexOf(item1.value);
      const index2 = user?.stores?.store.indexOf(item2.value);
      return index1 - index2;
    }
    return newData.length ? [newData?.sort(customSort)] : [[]];
  }, [data, user?.stores?.store]);
};

const ContextProvider = ({ children }) => {
  const [
    api,
    api_v2,
    api_v3,
    api_v4,
    useApiV1,
    useApiV2,
    setTokens,
    tokens,

    clearAll,
    user,
    refetch,
    loadingUser,
  ] = useAuthentication();
  const [parlor, setParlor, parlorData] = useParlor(user);
  const [storesData] = useStores(user);
  const [convert, setCurrency, currencies, currency, convertToCRM] = useCurrency();
  const [theme, setTheme, toggleTheme] = useTheme();
  const [userPrice] = useUserGetPrice(user);

  return (
    <CurrencyContext.Provider value={{ currencies, convert, setCurrency, currency, convertToCRM }}>
      <UserContext.Provider
        value={{
          ...user,
          user,
          api,
          api_v2,
          useApiV1,
          useApiV2,
          api_v3,
          api_v4,
          userPrice,
          setTokens,

          tokens,
          clearAll,
          storesData,
          refetch,
          loadingUser,
        }}
      >
        <ParlorContext.Provider value={{ parlorData, parlor, setParlor }}>
          <ThemeContext.Provider value={{ theme, setTheme, toggleTheme, parlorData }}>
            {children}
          </ThemeContext.Provider>
        </ParlorContext.Provider>
      </UserContext.Provider>
    </CurrencyContext.Provider>
  );
};

export default ContextProvider;
