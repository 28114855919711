export const statusModerateValue = {
  new: 'Новый',
  in_waiting: 'В очереди',
  cancel: 'Отклонено',
  in_process: 'В процессе',
  requires_clarification: 'Требует уточнения',
  approved: 'Завершено',
  filled_in: 'Залито',
};

export const statusModerateOptions = [
  {
    value: 'new',
    label: 'Новый',
  },
  {
    value: 'in_waiting',
    label: 'В очереди',
  },
  {
    value: 'cancel',
    label: 'Отклонено',
  },
  {
    value: 'in_process',
    label: 'В процессе',
  },
  {
    value: 'requires_clarification',
    label: 'Требует уточнения',
  },
  {
    value: 'approved',
    label: 'Завершено',
  },
  { value: 'filled_in', label: 'Залито' },
];

export const statusModerateColor = {
  new: 'rgb(135, 206, 250, 1)', // Светло-синий
  in_waiting: 'rgb(255, 165, 0, 1)', // Оранжевый
  cancel: 'rgb(253, 93, 147, 1)', // Красный
  in_process: 'rgb(30, 144, 255, 1)', // Голубой
  requires_clarification: 'rgb(255, 215, 0, 1)', // Желтый
  approved: 'rgb(146, 199, 19, 1)', // Зеленый
  filled_in: 'rgb(147, 112, 219, 1)', // Фиолетовый
};

export const categoryTicketValue = {
  crm: 'CRM',
  customer: 'Customer',
  site: 'Сайт vean-tattoo',
  shop: 'Магазин',
  mobile_app: 'Мобильное приложение',
};

export const categorySupportTicketValue = {
  general_question: 'Общий вопрос',
  crm: 'CRM',
  finance: 'Финансы',
  parlor: 'Салон',
  collective: 'Коллектив',
  manager: 'Управляющие',
  promo: 'Реклама',
  task: 'Задачи',
  student: 'Oбучение',
  security: 'Служба безопасности',
};

export const categoryTicketGeneralValue = { ...categoryTicketValue, ...categorySupportTicketValue };

export const categorySupportTicketOptions = [
  {
    value: 'general_question',
    label: 'Общий вопрос',
  },
  {
    value: 'crm',
    label: 'CRM',
  },
  {
    value: 'finance',
    label: 'Финансы',
  },
  {
    value: 'parlor',
    label: 'Салон',
  },
  {
    value: 'collective',
    label: 'Коллектив',
  },

  {
    value: 'manager',
    label: 'Управляющие',
  },
  {
    value: 'promo',
    label: 'Реклама',
  },
  {
    value: 'task',
    label: 'Задачи',
  },

  {
    value: 'student',
    label: 'Oбучение',
  },
  {
    value: 'security',
    label: 'Служба безопасности',
  },
];

export const categoryTicketOptions = [
  {
    value: 'crm',
    label: 'CRM',
  },
  {
    value: 'customer',
    label: 'Customer',
  },
  {
    value: 'site',
    label: 'Сайт vean-tattoo',
  },
  {
    value: 'shop',
    label: 'Магазин',
  },
  {
    value: 'mobile_app',
    label: 'Мобильное приложение',
  },
];

export const isBlockFunctional = window.location.hostname !== 'cr.vean-tattoo.pl';
