import React, { useCallback, useState } from 'react';
import Swal from 'sweetalert2';
import moment from 'moment';
import withReactContent from 'sweetalert2-react-content';
import { Row, Table, Col, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { ApolloProvider, useApolloClient, useQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListCheck } from '@fortawesome/free-solid-svg-icons';
import { SELL_HISTORY } from '../Querries/goodQueries.gql';
import i18next from 'i18next';

const MySwal = withReactContent(Swal);

const HistoryModal = ({ item }) => {
  const [page, setPage] = useState(0);

  const { data } = useQuery(SELL_HISTORY, {
    variables: { item: item.item.id, parlorId: item.parlor_id },
    fetchPolicy: 'cache-and-network',
  });

  const pageSize = 5;

  const getHistoryItemsByPage = (addedItems, page) => {
    const data = [...addedItems];
    return data
      .sort((a, b) => new Date(b.details.created_at) - new Date(a.details.created_at))
      .slice(page === 0 ? 0 : page * pageSize, page * pageSize + pageSize);
  };

  return (
    <Row
      style={{
        backgroundColor: '#2b383f',
        borderRadius: '8px',
        padding: '0',
      }}
    >
      <Table responsive>
        <thead>
          <tr>
            <th className='text-center'>{i18next.t('stat_7.sell')}</th>
            <th className='text-center'>{i18next.t('stat_extra_3.who_sell')}</th>
            <th className='text-center'>{i18next.t('add_expense.create')}</th>
          </tr>
        </thead>
        <tbody>
          {data?.store_item_by_pk.store_sellorderitems &&
            getHistoryItemsByPage(data.store_item_by_pk.store_sellorderitems, page)?.map((el, index) => (
              <tr key={index}>
                <td className='text-center'>{el.value}</td>
                <td className='text-center'>{el.details.seller_id}</td>
                <td className='text-center'>
                  {moment(el.details.created_at).format('YYYY-MM-DD')}
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      {data?.store_item_by_pk.store_sellorderitems.length / pageSize > 1 && (
        <Col xs={12} className='content d-flex justify-content-center align-items-center'>
          <Pagination>
            <PaginationItem disabled={page <= 0}>
              <PaginationLink onClick={() => setPage((prev) => prev - 1)}>Back</PaginationLink>
            </PaginationItem>
            <PaginationItem active>
              <PaginationLink>{page + 1}</PaginationLink>
            </PaginationItem>
            <PaginationItem
              disabled={page + 1 >= data.store_item_by_pk.store_sellorderitems.length / pageSize}
            >
              <PaginationLink onClick={() => setPage((prev) => prev + 1)}>Next</PaginationLink>
            </PaginationItem>
          </Pagination>
        </Col>
      )}
    </Row>
  );
};

export const SellsHistory = React.memo(({ item }) => {
  const client = useApolloClient();

  const getHistory = useCallback((item) => {
    MySwal.fire({
      title: i18next.t('stat_7.history_sell'),
      showCancelButton: false,
      confirmButtonText: i18next.t('buttons.close'),
      html: (
        <ApolloProvider client={client}>
          <HistoryModal item={item} />
        </ApolloProvider>
      ),
      width: '800px',
    }).then(() => {});
  }, []);
  return (
    <div>
      <div
        style={{
          display: 'flex',
          direction: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <FontAwesomeIcon
          icon={faListCheck}
          className='cursor-edit'
          onClick={() => getHistory(item)}
        />
      </div>
    </div>
  );
});
