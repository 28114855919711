import React from 'react';
import PropTypes from 'prop-types';
import { default as NativeSelect } from 'react-select';
import { FormFeedback, FormGroup, Label, FormText } from 'reactstrap';
import { selectStyles } from 'helpers';
import { Button } from 'reactstrap';
import i18next from 'i18next';

export default class Select extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  shouldComponentUpdate(nextProps) {
    return (
      nextProps.error !== this.props.error ||
      nextProps.value !== this.props.value ||
      nextProps.options !== this.props.options ||
      nextProps.touched !== this.props.touched ||
      nextProps.placeHolder !== this.props.placeHolder
    );
  }

  handleClick = () => {
    this.myRef.current.setValue(this.props.options);
  };

  render() {
    const {
      name,
      options,
      defaultValue,
      error,
      onChange,
      handleBlur,
      label,
      touched,
      placeHolder,
      required,
      groupStyle,
      inline,
      disabled,

      ...otherProps
    } = this.props;

    return (
      <FormGroup style={{ ...groupStyle, width: '100%' }}>
        {!inline ? (
          <dl className='row m-0'>
            {label && (
              <dt className='col-md-3'>
                <Label for={name}>{label}</Label>
              </dt>
            )}
            <dd className={label ? 'col-md-9 p-0' : 'col-md-12 p-0'}>
              <NativeSelect
                ref={this.myRef}
                name={name}
                id={name}
                options={options}
                defaultValue={defaultValue}
                onBlur={handleBlur}
                onChange={onChange}
                isDisabled={disabled}
                inline={inline}
                className={error && touched ? 'vean__container invalid' : 'vean__container'}
                styles={selectStyles(error && touched)}
                placeholder={placeHolder}
                required={required}
                classNamePrefix='vean'
                {...otherProps}
              />
              {this.props.isSelectAll && this.props.isMulti ? (
                <Button className={label ? 'col-md-9' : 'col-md-12'} onClick={this.handleClick}>
                  {i18next.t('select.select_all')}
                </Button>
              ) : (
                ''
              )}
              {otherProps.helpText && <FormText>{otherProps.helpText}</FormText>}
              {error && touched && <FormFeedback className='d-block'>{error}</FormFeedback>}
            </dd>
          </dl>
        ) : (
          <>
            <Label for={name}>{label}</Label>
            <NativeSelect
              ref={this.myRef}
              name={name}
              id={name}
              options={options}
              defaultValue={defaultValue}
              onBlur={handleBlur}
              onChange={onChange}
              isDisabled={disabled}
              styles={selectStyles(error && touched)}
              placeholder={placeHolder}
              required={required}
              classNamePrefix='vean'
              {...otherProps}
            />
            {otherProps.helpText && <FormText>{otherProps.helpText}</FormText>}
            {error && touched && <FormFeedback className='d-block'>{error}</FormFeedback>}
          </>
        )}
      </FormGroup>
    );
  }
}

Select.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  options: PropTypes.array.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  error: PropTypes.string,
  touched: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  onChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  placeHolder: PropTypes.string,
  required: PropTypes.bool,
  groupStyle: PropTypes.object,
};

Select.defaultProps = {
  placeHolder: 'Choose from the list...',
  touched: false,
  error: '',
  label: '',
  required: false,
  groupStyle: null,
  isSelectAll: true,
};
